import { useState, useEffect } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import { getViewData, getPublicViewData } from '../api/wagtail';
import LazyContainers from '../containers/LazyContainers';
import { ROUTE } from '../const';

export default function DynamicNotFoundPage() {
    // 404 does not support getServerSideProps, must fetch client side data
    // https://github.com/vercel/next.js/blob/master/errors/404-get-initial-props.md
    // const [data, setData] = useState(null);
    // useEffect(() => {
    //     async function fetchData() {
    //         const { json: pageData } = await getPublicViewData('404');
    //         setData(pageData);
    //     }
    //     fetchData();
    // }, []);

    // if (!data) {
    //     return null;
    // }

    return (
        <>
            <Head>
                <title>404 - Page Not Found | Fight Realm</title>
                <meta name="description" content="The page you're looking for cannot be found." />
            </Head>
            <div className="min-h-[300px] flex items-center justify-center bg-darkBg-700 py-12">
                <div className="text-center px-4">
                    <h1 className="text-6xl text-main-red mb-4">404</h1>
                    <h2 className="text-2xl text-white mb-6">Page Not Found</h2>
                    <p className="text-gray-400 mb-8">
                        The page you&apos;re looking for doesn&apos;t exist or has been moved.
                    </p>
                    <Link 
                        href={ROUTE.HOME}
                        className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base rounded-md text-black bg-main-green transition"
                    >
                        Return Home
                    </Link>
                </div>
            </div>
            <NotFoundPage />
        </>
    );
}

function NotFoundPage({ componentName, componentProps }) {
    const Component = LazyContainers[componentName];
    if (!Component) {
        return <h1>Component {componentName} not found</h1>;
    }
    return <Component {...componentProps} />;
}

/*
// For static routing
export async function getStaticProps({ params, preview, previewData }) {
  const pageData = await getViewData('404');
  return { props: pageData }
}
*/
